import React, { useState, useEffect } from "react";
import SwatchImagesPDP from "./SwatchImagesPdp";
import ProductAttributes from "./ProductAttributes";
import ColorSliderPdp from "./ColorSliderPdp";
import "../Products.css";
import ProductBrandLogos from "./ProductBrandLogos";
import ProductGallerySlider from "./ProductGallerySlider";
import instock_mark from "./media/instock_mark.png";
import popupOpener from "./media/popup_opener.png";
import PdpGalleryPopup from "./PdpGalleryPopup";
import SizeAvailables from "./SizeAvailables";

export default function ProductDetailPage({ product, type, sku }) {
  const [products, setProducts] = useState([]);
  const [sizeProducts, setSizeProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayPopup, setDisplayPopup] = useState("hide");
  const wpProductFilter = window.wpProductFilter;
  const wpProductCategory = window.wpProductCategory;
  const show_size_on_pdp = wpProductCategory.show_size_on_pdp
    ? wpProductCategory.show_size_on_pdp
    : "";
  const brand_collection = product.brand_collection;
  const allInStock = window.wpProductCategory.in_stock == "all" ? true : false;
  const inStock1 =
    product.in_stock !== "" || product.in_stock !== undefined
      ? product.in_stock
      : "";
  const inStock = allInStock === false ? inStock1 : "";

  const siteInfo = window.wpProductFilter.siteInfo;
  const getcouponbtn = siteInfo.getcouponbtn;
  const getcouponreplace = siteInfo.getcouponreplace;
  const getcouponreplacetext = siteInfo.getcouponreplacetext;
  const getcouponreplaceurl = siteInfo.getcouponreplaceurl;
  const show_financing = siteInfo.show_financing;
  const pdp_get_finance = siteInfo.pdp_get_finance;
  const getfinancereplace = siteInfo.getfinancereplace;
  const getcoupon_link = siteInfo.getcoupon_link;
  const showprice = siteInfo.plpshowprice;
  const showrugsbutton = siteInfo.showrugsbutton;
  const rugsbrands = siteInfo.rugsbrands;
  const currentPageID = siteInfo.currentPageID;
  const arearugshowsku = siteInfo.arearugshowsku;
  const rugpageurl = siteInfo.rugpageurl + "?id=" + currentPageID;

  const getfinancereplaceurl =
    getfinancereplace === "1"
      ? siteInfo.getfinancereplaceurl
      : "/flooring-financing/";
  const getfinancetext =
    getfinancereplace === "1" ? siteInfo.getfinancetext : "Financing";
  const manufacturerBrands = ["Bruce", "AHF", "Shaw"];
  var productManufacturer = manufacturerBrands.includes(product.manufacturer)
    ? product.brand.toLowerCase()
    : product.manufacturer.toLowerCase();
  var productManufacturer = productManufacturer.replace(/\s/g, "");

  useEffect(() => {
    fetchProducts();
    if (show_size_on_pdp === "yes") {
      fetchSizeWiseProducts();
    }
  }, [product]);

  const jsonld = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: product.name + " " + product.sku,
    image: `https://mm-media-res.cloudinary.com/image/fetch/h_600,w_600,c_limit/https://${product.swatch}`,
    description: product.name + " " + product.sku,
    sku: product.sku,
    mpn: product.sku,
    brand: {
      "@type": "thing",
      name: product.brand,
    },
    offers: {
      "@type": "offer",
      priceCurrency: "USD",
      price: "00",
      priceValidUntil: "",
    },
  };

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      brand_collection: brand_collection,
      category: type,
      in_stock: inStock,
      current_sku: product.sku,
    });

    try {
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);
      const data = await response.json();
      setProducts(data.products_group_by);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSizeWiseProducts = async () => {
    const query1 = new URLSearchParams({
      brand_collection: brand_collection,
      category: type,
      in_stock: inStock,
      show_size_on_pdp: show_size_on_pdp,
      color: product.color,
    });

    try {
      const response1 = await fetch(`${wpProductFilter.apiEndpoint}?${query1}`);
      const data1 = await response1.json();
      setSizeProducts(data1.products_group_by);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };
  const populatePopup = (status) => {
    setDisplayPopup(status);
  };
  return (
    <div className="product_detail_wrap">
      <div className="row">
        <div className="col-md-6 col-sm-12 product-swatch">
          <div className="imagesHolder">
            <SwatchImagesPDP
              swatch={product.swatch}
              populatePopup={populatePopup}
              height={600}
              width={600}
            />

            <div id="roomvo">
              <div className="roomvo-container">
                <a
                  className="roomvo-stimr button"
                  data-sku={product.sku}
                  style={{ visibility: "hidden" }}>
                  <i className="fa fa-camera" aria-hidden="true"></i> &nbsp;See
                  In My Room
                </a>
              </div>
            </div>

            <script type="text/javascript">
              {`       
                function getProductSKU() {                       
                  return "${productManufacturer}-${sku.toLowerCase()}";
                } 
              `}
            </script>
            <div class="image-expander">
              <img
                alt="zoom-img"
                class="img"
                src={popupOpener}
                onClick={(e) => populatePopup("show")}
              />
            </div>
          </div>
          <div className="product_gallery_slider">
            <ProductGallerySlider product={product} />
          </div>
        </div>
        <div className="col-md-6 col-sm-12 product-box">
          <div class="row">
            <div class="col-md-6">
              <h1 class="fl-post-title">{product.collection_name}</h1>
              <h2 class="fl-post-title">
                {arearugshowsku == "1" && product.z_prod_type === "rugs"
                  ? product.sku
                  : product.color}
              </h2>
              {inStock == 1 && showprice == 1 && product.price != 0 && (
                <div className="price">
                  <strong>
                    <sup>$</sup>
                    {product.price}
                  </strong>
                  &nbsp;/<sub>{product.price_unit}</sub>
                </div>
              )}
            </div>
            <div class=" col-md-6 text-right">
              <ProductBrandLogos product={product} />
              {product.in_stock == 1 && (
                <div class="col-lg-12 MarkInstock">
                  <img
                    src={instock_mark}
                    className="Instock-mark"
                    alt="Instock-Mark"
                  />
                  <span>IN-STOCK</span>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="product-colors">
                <ul>
                  <li className="found">
                    {products[Object.keys(products)[0]]?.length}
                  </li>
                  <li className="colors"> COLORS AVAILABLE </li>
                </ul>
              </div>
              <div id="product-colors">
                {products[Object.keys(products)[0]] ? (
                  <ColorSliderPdp
                    products={products[Object.keys(products)[0]]}
                    currentProductSku={product.sku}
                    currentProductColor={
                      Object.keys(sizeProducts).length > 0 ? product.color : ""
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {Object.keys(sizeProducts).length > 0 && (
            <SizeAvailables
              products={sizeProducts[Object.keys(sizeProducts)[0]]}
              sku={product.sku}
            />
          )}
          <div className="button-wrapper">
            <div className="dual-button">
              <a href="/contact-us/" className="button contact-btn">
                Contact Us
              </a>

              {(pdp_get_finance != 1 || pdp_get_finance == "") && (
                <a href={getfinancereplaceurl} className="finance-btn button">
                  {getfinancetext}
                </a>
              )}
            </div>

            {getcouponbtn === "1" ? (
              <a href={getcoupon_link} className="button alt getcoupon-btn">
                <span className="fl-button-text">GET COUPON</span>
              </a>
            ) : (
              ""
            )}

            {getcouponreplace === "1" &&
            (getcouponreplaceurl !== undefined && getcouponreplaceurl) !== "" &&
            getcouponreplacetext !== "" &&
            getcouponreplacetext !== undefined ? (
              <a
                href={getcouponreplaceurl}
                className="button alt custompdpbtn getcoupon-btn">
                {getcouponreplacetext}
              </a>
            ) : (
              ""
            )}

            {showrugsbutton === "1" &&
            rugsbrands.indexOf(product.brand) !== -1 ? (
              <a href={rugpageurl} class="floorvana-btn button">
                CREATE A RUG
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="product-attributes-wrap">
            <ProductAttributes product={product} />
          </div>
        </div>
      </div>
      {/* <div className={`pdpGalleryImagePopup ${displayPopup}`}> */}
      <div className={`pdpGalleryImagePopup ${displayPopup}`}>
        <div className="closePopup">
          <span onClick={(e) => populatePopup("hide")}>X</span>
        </div>
        <PdpGalleryPopup
          swatch={product.swatch}
          galleryImages={product.gallery_images}
          height={800}
          width={800}
          productName={`${product.name} ${product.sku}`}
        />
      </div>

      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
    </div>
  );
}
